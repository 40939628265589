// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'jquery'
import 'jquery-ui'
import Rails from 'jquery-ujs';
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("trix")
require("@rails/actiontext")
import 'trix/dist/trix.css'
import "../stylesheets/actiontext.scss"

import datepickerFactory from 'jquery-datepicker'
datepickerFactory($)
import '../javascript/wice_grid'

Turbolinks.start()
ActiveStorage.start()
